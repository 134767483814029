export default {
  lang: "en",
  app: {
    title: "Healex | Stuget",
    description: "Studget web app",
  },
  login: {
    title: "Costing tool for clinical studies",
    login: "Login studget",
    "login-with": "Login with Healex ClinicalSite",
    "register-with": "Register via Healex Self Service",
    recovery: "Forgot your password?",
    form: {
      username: "User name",
      password: "Password",
    },
    button: {
      register: "Register",
      signin: "Sign in",
    },
    anotherSessionOpened: {
      title: "Session Warning",
      description:
        "This session is already opened by another user! Do you wish to close it and continue in this one?",
    },
  },
  register: {
    form: {
      name: "Name",
      mail: "Email",
      comment: "",
    },
    button: {
      send: "Send",
      back: "Back",
    },
    title: "Register",
  },
  passwordRecovery: {
    button: {
      recover: "Recover",
      back: "Back",
    },
    title: "Password Recovery",
    redirect: "You are being redirected to ClinicalSite reset password in: ",
    alias: "example{'@'}example.com",
    "invalid-email": "Please enter a valid email",
    field: "Email",
  },
  caselist: {
    dateformat: "MM-DD-YYYY HH:mm:ss",
  },
  views: {
    "master-form": {
      deafult: "Default master form",
      title: "Master form",
      "title-list": "Master forms ({0})",
      name: "Name: ",
      nameForm: "Name Master form",
      status: "Status: ",
      "organizational-unit": "Organizational Unit: ",
      "last-modified": "Last Modified: ",
      allowPerPatients: "Per patient calculation",
      none: "None",
      parent: "Parent",
      nameInstructions: "Please enter a name",
      OUInstructions: "Please select an organizational unit",
      organizationUnitRequired: "Organizational unit is required",
      nameRequired: "Name is required",
      parentRequired: "Parent is required",
      selectSummations: "Select totals",
      view: "View mode",
      addItem: "Add Item",
      addTask: "Add Task",
      addTaskCategory: "Add Task Category",
      addSummation: "Add Summation",
      deleteMainCategory: "Delete Main Category",
      addMainCategory: "Add Main Category",
      deleteCostCategory: "Delete Cost Category",
      addCostCategory: "Add Cost Category",
      buttons: {
        addMaterialCostItem: "Add Material Cost Item",
      },
      messages: {
        missingMaterialCostItem: "You must select a material cost item.",
        missingMaterialCostType: "You must select a material cost type.",
        materialCostAlreadyExists: {
          title: "Item already exists",
          text: "The selected material cost item is already added in the cost category",
        },
      },
      modals: {
        costItemSelection: {
          globalSearchLabel: "Search in all available catalogs",
          category: "Category",
          unitName: "Unit",
          information: "Information",
        },
      },
    },
    pageNofound: {
      Title: "Oops! Page not found",
      goToHome: "Go to Home",
      subtitle: "Sorry, the page you're looking for doesn't exist.",
    },
    confirmationEmail: {
      Title: " Your E-mail has been confirmed",
      goToLogin: "Go to login",
    },
    customer: {
      deafult: "Default customer",
      title: "Customer",
      ShowCustomersEnable: "Show customers Enable",
      AddCustomer: "Add Customer",
      EditCustomer: "Edit Customer",
      RemoveCustomer: "Remove Customer",
      RemoveCustomerPermanently: "Remove permanently",
      ModalRemove: {
        title: "Are you sure that you want to delete?",
        subtitle: "The customer will be remove definately from the database",
      },
      ActivateCustomer: "Activate Customer",
      GoToUser: "User",
      EnterCustomerName: "Enter customer name",
      Cancel: "Cancel",
      fields: {
        id: "Id",
        name: "Name",
        enabled: "Enabled",
        unlimited: "Unlimited",
        maxUsers: "Max users",
        currentUser: "Current User",
      },
      CustomerUpdated: "Customer Updated",
      CustomerUpdatedSuccessfuly: "The customer was updated successfuly",
      ErrorOnUpdate: "It was an error on update",
      Error: "Error",
      CustomerActivated: "Customer Activated",
      CustomerActivatedSuccessfuly: "The customer was activated successfuly",
      ErrorOnActivate: "It was an error on activate",
      CustomerRemoved: "Customer Removed",
      CustomerRemovedSuccessfuly: "The customer was removed successfuly",
      ErrorOnRemove: "It was an error on remove",
      features: "Features",
    },
    ou: {
      deafult: "Default organizational unit",
      ShowEnable: "Show Organizational units Enable",
      Add: "Add Organizational Unit",
      Edit: "Edit Organizational Unit",
      Remove: "Remove Organizational Unit",
      Activate: "Activate Organizational Unit",
      EnterName: "Enter Organizational Unit name",
      OrganizationalUnitNameRequired: "Organizational Unit name is required",
      modalOU: {
        nameLabel: "Name",
        nameInvalidFeedback: "Organizational Unit name is required",
        namePlaceholder: "Enter Organizational Unit name",
        emailLabel: "Reviewer distribution email",
        emailInvalidFeedback: "A valid email is required",
        emailPlaceholder: "Enter default email for notifications",
      },
      Cancel: "Cancel",
      fields: {
        Id: "Id",
        Name: "Name",
        Enabled: "Enabled",
      },
      Updated: "Organizational Unit Updated",
      UpdatedSuccessfuly: "The Organizational Unit was updated successfuly",
      ErrorOnUpdate: "It was an error on update",
      Error: "Error",
      Activated: "Organizational Unit Activated",
      ActivatedSuccessfuly: "The Organizational Unit was activated successfuly",
      ErrorOnActivate: "It was an error on activate",
      Removed: "Organizational Unit Removed",
      RemovedSuccessfuly: "The Organizational Unit was removed successfuly",
      ErrorOnRemove: "It was an error on remove",
      Parameters: "Parameters",
      title: "Organizational units",
      showEnabled: "Show activated organizational units",
      deactivateOU: "Deactivate organizational unit",
    },
    settings: {
      title: "Administrative Costs",
      subtitle: "Salaries",
      Customers: "Customers",
      OrganizationalUnit: "Organizational Unit",
      ChangeMasterForm: "Change Master Form",
      SalaryType: "Salary Type: ",
      PleaseSelectAnOption: "Please select an option",
      fields: {
        1: "Salary Year 1",
        2: "Salary Year 2",
        3: "Salary Year 3",
        4: "Salary Year 4",
        5: "Salary Year 5",
        6: "Average",
        Id: "Id",
        CategoryName: "Category Name",
        Average: "Average",
      },
      administrativeCostCategories: "Category",
      selectSalaryType: "Please select a salary type.",
      newSalaryType: "New Salary Type",
      updateSalaryType: "Update Salary Type",
      salaryTypeName: "Salary type name",
      workingHours: "Annually working hours",
      deleteSalaryType: "Delete salary type",
      confirmSalaryTypeDelete:
        "Are you sure you want to delete the salary type?",
      customer: {
        title: "Customer specific settings",
        months: "Duration (months) switch for averages",
        surcharge: "Surcharge (percent) for external personnel-costs",
        cancel: "Cancel",
      },
      context: {
        addSeniority: "Add seniority",
        deleteSeniority: "Delete seniority",
        addCategory: "Add category",
        deleteCategory: "Delete category",
      },
    },
    home: {
      title: "Home",
      MasterForm: "Master Forms",
      Settings: "Settings",
      AdministrativeCosts: "Administrative Costs",
      OrganizationalUnits: "Organizational units",
      Customers: "Customers",
      Users: "Users",
      Profile: "Profile",
      SignOut: "Sign Out",
      Surcharges: "Surcharges",
      Conditions: "Conditions",
      sort: "sort",
      CaseTemplateList: "Cost calculation templates",
      status: "Status",
      statusCases: {
        open: "Open",
        edition: "Edition",
        closed: "Closed",
      },
      duplicateSubcase: "Duplicate partial cost calculation",
      generalSummations: "Totals",
      about: "About",
      help: "Help",
      duplicateCase: "Duplicate Cost calculation",
      caseName: "Case name:",
      caseNameRequired: "Case name is required",
    },
    users: {
      title: "Users",
      ShowUsersEnable: "Show activated users",
      AddUser: "Add user",
      EditUser: "Edit user",
      RemoveUser: "Deactivate user",
      RemovePermanentlyUser: "Delete user permanently",
      ActivateUser: "Activate user",
      ManageRoles: "Manage roles",
      ChangePassword: "Reset password",
      Message: "Are you sure you want to reset your password?",
      fields: {
        UserName: "User Name",
        Name: "Name",
        Mail: "Mail",
        role: "Role",
      },
      UserDisabledSuccessfully: "User disabled Successfully",
      DeactivateUser: "Deactivate user",
      UserActivatedSuccessfully: "User activated successfully",
      ResetingPasswordSuccessfully: "Reseting password successfully",
      ResetingPassword: "Reseting password",
      DeletePermanentlyTitle: "Delete user permanently",
      DeletePermanentlyMessage:
        "Are you sure you want to delete the user permanently?",
      DeletePermanentlyCases:
        "The user has open cases. Are you sure you want to delete the user permanently? ",
    },
    user: {
      title: "User",
      NewUser: "New user",
      SaveUser: "Save user",
      GeneralData: "General data",
      User: "User:",
      EnterUser: "Enter user",
      Name: "Name:",
      EnterName: "Enter name",
      Email: "Email",
      EnterEmail: "Enter email",
      Customer: "Customer",
      EnterCustomer: "Enter customer",
      UserDataLoaded: "User data loaded",
      UserLoading: "User loading",
      Required: "Required",
      RequiredEmail: "Required valid email.",
    },
    roles: {
      UserDataLoaded: "User data loaded",
      UserLoading: "User loading",
      AddRole: "Add role",
      RemoveRole: "Remove role",
      OU: "OU:",
      Roles: "Roles:",
      fields: {
        IdOrganizationalunit: "Id Organizationalunit",
        OrganizationalUnitName: "OrganizationalUnit Name",
        RoleName: "Role Name",
      },
      LoadingRoles: "Loading roles",
      LoadingOrganizationalUnits: "Loading organizationalUnits",
      Warning: "Warning !",
      atLeastOneRole: "You should add at least one role to leave ",
    },
    surcharges: {
      title: "Surcharges",
      ShowSurchargeEnable: "Show activated surcharges",
      AddSurcharge: "Add Surcharge",
      EditSurcharge: "Edit Surcharge",
      RemoveSurcharge: "Deactivate surcharge",
      ActivateSurcharge: "Activate Surcharge",
      EnterSurchargeName: "Enter surcharge name",
      Cancel: "Cancel",
      fields: {
        Id: "Id",
        Name: "Name",
        Enabled: "Enabled",
      },
      SurchargeUpdated: "Surcharge Updated",
      SurchargeUpdatedSuccessfuly: "The surcharge was updated successfully",
      ErrorOnUpdate: "There was an error on update",
      Error: "Error",
      SurchargeAdded: "Surcharge Added",
      SurchargeAddedSuccessfuly: "The surcharge was added successfully",
      ErrorOnAdd: "There was an error on adding",
      SurchargeActivated: "Surcharge Activated",
      SurchargeActivatedSuccessfuly: "The surcharge was activated successfully",
      ErrorOnActivate: "There was an error on activation",
      SurchargeRemoved: "Surcharge Removed",
      SurchargeRemovedSuccessfuly: "The surcharge was removed successfully",
      ErrorOnRemove: "There was an error on removal",
      EditNameCondition: " edition",
    },
    conditions: {
      ShowConditionEnable: "Show enabled conditions",
      AddCondition: "Add Condition",
      EditCondition: "Edit Condition",
      RemoveCondition: "Remove Condition",
      ActivateCondition: "Activate Condition",
      EnterConditionName: "Enter condition name",
      ConditionNameRequired: "Condition name is required",
      Cancel: "Cancel",
      fields: {
        Id: "Id",
        Name: "Name",
        Enabled: "Enabled",
      },
      ConditionUpdated: "Condition Updated",
      ConditionUpdatedSuccessfuly: "The condition was updated successfully",
      ErrorOnUpdate: "There was an error on update",
      Error: "Error",
      ConditionAdded: "Condition Added",
      ConditionAddedSuccessfuly: "The condition was added successfully",
      ErrorOnAdd: "There was an error on adding",
      ConditionActivated: "Condition Activated",
      ConditionActivatedSuccessfuly: "The condition was activated successfully",
      ErrorOnActivate: "There was an error on activation",
      ConditionRemoved: "Condition Removed",
      ConditionRemovedSuccessfuly: "The condition was removed successfully",
      ErrorOnRemove: "There was an error on removal",
      EditNameCondition: " edition",
      title: "Conditions",
      showEnabled: "Show activated conditions",
      deactivateCondition: "Deactivate condition",
    },
    subCases: {
      NameCase: "Cost calculation",
      nameSubcase: "Subcase Name",
      case: "Cost calculation",
      buttons: {
        view: "View mode",
        edit: "Edit",
        closeCase: "Close case",
        export: "Export",
        print: "Print",
        comment: "Comment",
      },
      sections: {
        StudyTypeSelection: {
          title: "Study Type:",
          numPatients: "Number of Patients",
          perPatients: "Per patient",
          selectionOptionTrue: "Calculation for single included patient",
          selectionOptionFalse: "Calculation for maximum number of patients",
          selectOneItem: "Please select some item",
        },
      },
      newSubcase: "New Subcase",
      nameInstructions: "Please enter a subcase name",
      missingName: "Missing name",
      numPatientsInvalid: "Please check the number of patients",
    },
    cases: {
      newCase: "New Case",
      organizationUnit: "Organizational Unit",
      organizationUnitRequired: "Organizational unit is required",
      studyTypeRequired: "Study type is required",
      organizationUnitInstructions: "Please select a organizational unit",
      name: "Name",
      nameRequired: "Case name is required",
      notAllowedSymbols:
        "The case name cannot contain any of the following symbols: / * ? [ ] : |",
      nameInstructions: "Please enter a case name",
      duration: "Duration",
      durationRequired: "Duration is required",
      durationInMonths: "Please enter a case duration (in months)",
      masterForm: "Master Form",
      caseTemplate: "Case Template",
      masterFormRequired: "Master form is required",
      caseTemplateRequired: "Case template is required",
      masterFormInstructions: "Please select a master form",
      caseTemplateInstructions: "Please select a case template",
      studyTypeInstructions: "Please select a study type",
      creatingCase: "Creating case...",
      lastModified: "Last Modified",
      ascending: "Ascending",
      descending: "Descending",
      SortBy: "Sort by",
      FilterBy: "Filter by",
      status: {
        1: "Open",
        2: "Closed",
        3: "Edition",
      },
      title: "Cost calculation",
      selectCase: "Choose a cost calculation",
      default: "Default",
    },
    userProfile: {
      userProfile: "User Profile",
      userEditOptions: {
        editProfile: "Edit Profile",
        changePassword: "Reset Password",
      },
      userAttributes: {
        name: "Name",
        nameInstructions: "Enter name",
        email: "E-mail",
        emailInstructions: "Enter e-mail",
        SMSAccount: "ClinicalSite Account",
        customer: "Customer",
        link: "Link account",
        linkSuccess: "ClinicalSite Account linked",
        success: "Success",
      },
      cancel: "Cancel",
    },
    caseTemplateEdition: {
      addNewGeneralSummation: "Add total",
      basisInformation: "Basic Information",
      structure: "Structure",
      parameters: "Parameters",
      materialCosts: "Material Costs",
      summations: "Summations",
      subcaseName: "Partial cost calculation",
      masterForm: "Partial cost calculation template",
      blockedStatus: "Locked",
      blocked: "Locked (Status: locked / unlocked)",
      addRow: "Add row",
      editRow: "Edit row",
      deleteRow: "Delete row",
      "delete-template": "Delete field",
      deleteGS: "Delete general summation",
      unblocked: "Unblocked",
      subcaseNameDescription: "Please enter the name of the subcase",
      newRow: "New Row",
      saveRow: "Save Row",
      updateRow: "Update Row",
      subcaseNameRequired: "Subcase name is required",
      deleteConfirm: "Are you sure you want to delete this row?",
      "delete-case-template": "Are you sure you want to delete this field?",
      deleteConfirmGS: "Are you sure you want to delete general summation?",
      moveUp: "Move Up",
      moveDown: "Move Down",
      editParameter: "Edit Parameter ",
      defaultValueRequired: "Default value for parameter is required",
      notAllowedValue: "Negative values are not allowed",
      defaultValueDescription: "Default value for parameter",
      defaultValue: "Default value",
      parameter: "Parameter",
      parameterInstructions: "Please select a parameter",
      parameterRequired: "Parameter is required",
      addParameter: "Add parameter",
      newParameter: "New Parameter",
      idParameter: "Id",
      parameterName: "Parameter Name",
      deleteParameter: "Delete Parameter",
      showSubcases: "Show cost calculation",
      showBudget: "Show Budget",
      generalSummation: "Totals",
      Name: "Name",
      EditName: "Edit name",
      NameIsRequired: "Name is required",
      fields: {
        title: "Additional fields",
        "add-field": "Add field",
        "new-field": "Additional field",
        "edit-field": "Edit field",
        "update-field": "Edit field",
        "delete-field": "Delete field",
        "update-name": "Update name",
        "update-study-type": "Update study type",
        "update-duration": "Update duration",
        "move-up": "Move field up",
        "move-down": "Move field down",
        "ok-new": "Add field",
        name: "Name",
        "name-invalid": "Name is required",
        "name-placeholder": "Please enter the name of the field",
        value: "Example",
        "value-invalid": "Value is required",
        "value-placeholder": "Please enter the default value of the field",
        "study-type": "Study type",
        "select-study": "Please select a study type",
        mandatory: "Mandatory",
        "mandatory-true": "Yes",
        "mandatory-false": "No",
        reviewer: "Only for reviewer:",
        "field-title-name": "Name",
        "field-title-value": "Example",
        "field-title-mandatory": "Mandatory",
        "field-title-reviewer": "Only for reviewer",
        "generic-forbidden": "The user cannot perform this action.",
        idSubcase: "Subcase ID",
        subcaseName: "Subcase",
        costCategoryName: "Category",
        costItemName: "Item Name",
        costItemCost: "Individual cost",
        costItemFinalCost: "Total cost",
      },
    },
    caseTemplateList: {
      screeTitle: "List of cost calculation templates",
      ShowEnableCaseTemplates: "Show activated cost calculation templates",
      AddCaseTemplate: "Add cost calculation template",
      EditCaseTemplate: "Edit cost calculation template",
      createANewCaseTemplate: "Add new cost calculation template",
      name: "Name",
      id: "Id",
      newTemplateName: "Name of the cost calculation template",
      nameRequired: "Case Template Name Required",
      OU: "Organizational Unit",
      OURequired: "Organizational Unit Required",
      OUInstructions: "Please select an organizational unit",
      removeCaseTemplate: "Deactivate cost calculation template",
      activate: "Activate cost calculation template",
    },
    parameters: {
      title: "Parameters",
      addParameter: "Add Parameter",
      editParameter: "Edit Parameter",
      deleteParameter: "Delete parameter",
      showEnabledParameters: "Show activated parameters",
      Activate: "Activate Parameter",
      enterParameterValue: "Enter value",
    },
    generalSummations: {
      title: "Totals",
      enterSummationName: "Enter general summation name",
      summationAddedSuccessfuly: "The general summation was added successfully",
      errorOnAdd: "There was an error on adding",
      generalSummationAdded: "General Summation Added",
      editSummationName: " edition",
      deafult: "Default general summation",
      updated: "General Summations Updated",
      updatedSuccessfuly: "The General Summations was updated successfuly",
      removed: "General Summation Removed",
      removedSuccessfuly: "The General Summation was removed successfuly",
      activated: "General Summation Activated",
      activatedSuccessfuly: "The General Summation was activated successfuly",
      addGeneralSummation: "Add total",
      editGeneralSummation: "Edit total",
      removeGeneralSummation: "Deactivate total",
      activateGeneralSummation: "Activate total",
      cancel: "Cancel",
      showSummationEnable: "Show activated totals",
      fields: {
        id: "Id",
        name: "Name",
        enabled: "Enabled",
      },
    },
    help: {
      dateformat: "MM-DD-YYYY",
      "file-name-title": "File Name",
      modal: {
        "create-article": "New article",
        "update-article": "Edit article",
        "delete-article": "Delete article",
        "delete-article-message":
          "Are you sure you want to delete the article?",
        "download-files": "Download file",
        "upload-files": "Article files",
        "new-file": "New file",
        "edit-file": "Edit file",
        "delete-file": "Delete file",
        "create-article-label-name": "Name:",
        "invalid-feedback-name": "Please enter a valid name",
        "create-article-placeholder-name": "Please enter a name",
        "create-article-label-description": "Description:",
        "invalid-feedback-description": "Please enter a valid description",
        "create-article-placeholder-description": "Please enter a description",
        "new-file-label": "File:",
        "invalid-feedback-file": "Please select a file",
        "new-file-placeholder": "no file chosen",
        "browse-file-placeholder": "Browse",
        "edit-file-label": "File:",
        "edit-file-placeholder": "no file chosen",
        "delete-file-message": "Are you sure you want to delete the file?",
        "file-size": "Select a file size less than 5mb",
        "file-no-pdf": "Please select a PDF",
        "temporal-errorMessage": "Something went wrong",
      },
      buttons: {
        "create-article": "New article",
        "update-article": "Edit article",
        "delete-article": "Delete article",
        "download-files": "Download article files",
        "new-file": "New file",
        "edit-file": "Edit file",
        "delete-file": "Delete file",
        close: "Close",
        next: "Next",
      },
    },
    reports: {
      title: "Reports",
      dateFormat: "DD-MM-YYYY HH:mm",
      materialReportTitle: "Material Cost Report",
      personalReportTitle: "Personnal Cost Report",
      buttons: {
        startDateLabel: "Start Date",
        endDateLabel: "Final Date",
        statusLabel: "Status",
        selectStatus: "Select a status:",
        searchPersonalReport: "Create report",
        exportTooltip: "Export report",
        datePickerReset: "Reset",
        datePickerLabel: "Use cursor keys to navigate calendar dates",
        noTagsAvailable: "No more status available",
      },
      fields: {
        idCase: "ID Cost Calculation",
        caseName: "Cost Calculation Name",
        idOrganizationUnit: "ID of the Organizational Unit",
        organizationUnitName: "Name of the Organizational Unit",
        idStatus: "Status",
        statusChangeDate: "Status Change Date",
        resourceName: "Resource",
        salaryType: "Salary Type",
        category: "Category",
        years: "Duration",
        annualCost: "Annual Cost",
        hourlyRate: "Hourly Rate",
        externalRate: "External Rate",
        totalResourceCost: "Total Resource Cost",
        totalResourceExternal: "Total Resource External",
        totalResourceHours: "Total Resource Hours",
      },
      materialCostFields: {
        subcaseName: "Subcase Name",
        itemName: "Catalogue",
        individualCost: "Individual Cost",
        totalCost: "Total Cost",
        information: "Information",
        count: "Count",
        costCategory: "Unit",
        catalogCategory: "Category",
      },
    },
  },
  HeaderCase: {
    ChangeName: {
      title: "Change case name",
      form: {
        Name: "Name",
        "Name-invalid-feedback": "Name is required",
      },
    },
    ChangeOwner: {
      title: "Change Owner",
      newOwner: "New owner",
      NameRequired: "Name is required",
      PleaseSelectOption: "Please select an option",
    },
    ClosingCase: {
      title: "Closing Case",
      confirmation: "Are you sure to close the case?",
      closed: "Closed",
    },
    Display: {
      Internal: "Internal",
      External: "External",
      Totals: "Totals",
    },
    Edit: {
      Internal: "Internal",
      External: "External",
      Totals: "Totals",
      Open: "Open",
    },
    Fields: {
      Subcase: "Subcase",
      Summation: "Summation",
      Cost: "Cost",
      Budget: "Budget",
      Difference: "Difference",
    },
    AcceptCase: {
      title: "Accept case",
      acceptingCaseError: "An error occurred",
      acceptingCaseSuccessful: "Case successfully accepted",
      confirmation: "Are you sure to accept the case?",
      missingFields:
        "The mandatory fields have not been filled in. The cost calculation can only be accepted when all mandatory fields have been filled in.",
    },
    SubmittedCase: {
      title: "Submit case",
      submittedCaseSuccessful: "Case successfully submitted",
      confirmation: "Are you sure to submit the case?",
    },
    ReturnCase: {
      title: "Return case",
      returnCaseSuccessful: "Case returned submitted",
      confirmation: "Are you sure to return the case?",
    },
    ReviewersDistributionList: {
      tabTitle: "Reviewers",
      title: "List of reviewers",
      addReviewer: "Add reviewer",
      reviewerLabel: "Reviewer:",
      reviewerInvalidFeedback: "Please select a user",
      reviewerPlaceholder: "Select a reviewer",
      deleteReviewer: "Delete reviewer",
      reviewerConfirmation: "Are you sure you want to delete the reviewer?",
    },
    HeaderToolbar: "Header Toolbar",
    DisplayText: "Display",
    EditText: "Edit",
    VisualizationModeTitle: "Enter visualization mode",
    VisualizationMode:
      "Shortly you will be able to see the header information in visualization mode",
    EditModeTitle: "Enter edit mode",
    EditMode:
      "Shortly you will be able to see the header information in edit mode",
    OrganizationalUnit: "Organizational unit: ",
    Owner: "Owner: ",
    MothsDuration: "Duration (in month):",
    CreatedOn: "Created on: ",
    CreatedBy: "Created by: ",
    LastModified: "Last Modified: ",
    ClosedOn: "Closed on: ",
    Status: "Status: ",
    UnsavedChangesTitle: "Unsaved changes",
    UnsavedChangesMessages:
      "Do you really want to leave? you have unsaved changes!",
    Open: "Open",
    StudyType: "Study Type:",
    None: "None",
    UserCollaboration: "User Collaboration",
    RemoveUser: "Remove",
    AddUser: "Add User",
    userName: "User Name",
    name: "Name",
    mail: "Email",
    actions: "Actions",
    caseBeingEdited:
      "The case is being edited by other user, do you still want to edit it?",
    reopenCase: "Are you sure to reopen the case for editing?",
  },
  messages: {
    212: "The Organizational Unit cannot be disabled, since there is at least one assigned user.",
    213: "The Organizational Unit cannot be disabled, since there is at least one case that is not in status closed.",
    titles: {
      warning: "Warning",
      DeleteCase: "Delete Case",
      CloseCase: "Closing Case",
      ExitNotSaved: "Changes not saved",
      Success: "Success",
      Error: "Error",
    },
    prompts: {
      DeleteCase: "Do you really want to delete this case?",
      CloseCase: "Do you really want to close the case?",
      ExitNotSaved: "Do you really want to leave? You have unsaved changes.",
    },
    MaxTaskResources:
      "This task already has the max number of resources available.",
    MasterFormUpdated: "Master form was successfully updated",
    MasterFormNotUpdated: "Master form could not be saved",
    OpenCaseError: "Could not open new case.",
    ErrorSaving: "There was an error when saving",
    masterFormViewMode:
      "Shortly you will be able to see the master form in view mode",
    subcaseViewMode: "Shortly you will be able to see the subcase in view mode",
    externalLowerCost: "External cost is less than internal cost.",
    CaseSubmittedCanNotEdited: "Case with submitted status can not be edited.",
  },
  placeholders: {
    TaskDescription: "Task Description",
    Comment: "Comment",
    NoInformationAdded: "No information added",
  },
  fields: {
    TimeCategories: {
      Description: "Description",
      Name: "Name",
    },
    CostCategories: {
      Item: "Item",
      identifier: "Identifier",
      limitedCatalogs: "Limited Catalogs",
    },
    MainCategories: {
      Category: "Category",
    },
    TaskCategories: {
      Resource: "Resource",
      Task: "Task",
      Hours: "Hours",
      Parameters: "Parameter value",
      PerPatient: "€ Per patient",
    },
    ResourcePool: {
      Resource: "Resource",
      ResourcePool: "Resource Pool",
      SalaryType: "Salary Type",
      Category: "Category",
      Years: "Years",
      AnnualCost: "Annual Cost",
      HourlyRate: "Hourly Rate",
      ExternalRate: "External Rate",
      TotalResourceCost: "Total Resource Cost",
      TotalResourceExternalCost: "Total Resource External",
      TotalResourceHours: "Total Resource Hours",
    },
    Resources: "Resources",
    Summations: {
      Summations: "Summations",
      Name: "Name",
      Final: "Final €",
      Value: "Surcharge Value",
      SelectFields: "Edit Summation",
      CostCategories: "Cost Categories",
      CostItems: "Cost Items",
      MainCategories: "Main Categories",
      TaskCategories: "Task Categories",
      Tasks: "Tasks",
      SummationsHeader: "Totals",
      OnlyPercentage: "Only Percentage",
      PerPatient: "Per Patient",
      OnlyInternal: "Only Internal",
    },
    Home: {
      Status: "Status",
      Cases: "Cases",
      CaseName: "Case Name",
      OrganizationalUnit: "Organizational Unit",
      Owner: "Owner",
      CreatedBy: "Created by",
      CreatedOn: "Created on",
      LastModified: "Last Modified",
      ClosedOn: "Closed on",
      IdCase: "Case ID",
    },
    ParameterPanel: {
      Id: "Id",
      Name: "Name",
      Enabled: "Enabled",
      Value: "Value",
    },
  },
  CostCategories: {
    Item: "Item",
  },
  MainCategories: {
    Category: "Category",
  },
  TaskCategories: {
    Resource: "Resource",
    Task: "Task",
    Hours: "Hours",
  },
  ResourcePool: {
    Resource: "Resource",
    ResourcePool: "Resource Pool",
    SalaryType: "Salary Type",
    Category: "Category",
    Years: "Years",
    AnnualCost: "Annual Cost",
    HourlyRate: "Hourly Rate",
    ExternalRate: "External Rate",
  },
  Summations: {
    Summations: "Summations",
    Name: "Name",
    Final: "Final €",
    Value: "Surcharge Value",
    SelectFields: "Select Fields",
    CostCategories: "Cost Categories",
    CostItems: "Cost Items",
    MainCategories: "Main Categories",
    TaskCategories: "Task Categories",
    Tasks: "Tasks",
  },
  Home: {
    Status: "Status",
    Cases: "Cases",
    CaseName: "Case Name",
    OrganizationalUnit: "Organizational Unit",
    Owner: "Owner",
    CreatedBy: "Created by",
    CreatedOn: "Created on",
    LastModified: "Last Modified",
    ClosedOn: "Closed on",
    AddCase: "Create cost calculation",
    SelectHeaderCase: "Select a case",
    displayHeaderCase: "Show cost calculation details",
    RemoveCase: "Delete cost calculation",
    PrintCase: "Print cost calculation",
    AddSubcase: "Add partial cost calculation",
    DisplaySubcase: "Show partial cost calculation",
    RemoveSubcase: "Delete partial cost calculation",
    SubcasesNotFound: "Sub cases not found",
    MoveSubcaseUp: "Move subcase up",
    MoveSubcaseDown: "Move subcase down",
  },
  buttons: {
    AddItem: "Add Item",
    RemoveItem: "Remove item",
    AddTaskCategory: "Add Task Category",
    AddTimeCategory: "Add Information Category",
    DeleteTimeCategory: "Delete Information Category",
    AddCostCategory: "Add Cost Category",
    DeleteCostCategory: "Delete Cost Category",
    AddMainCategory: "Add Main Category",
    DeleteMainCategory: "Delete Main Category",
    AddResource: "Add Resource",
    AddTask: "Add Task",
    AddCategory: "Add Category",
    AddCase: "Add Case",
    ChangeOwner: "Change Owner",
    OK: "OK",
    Cancel: "Cancel",
    SaveAndExist: "Save and Exit",
    discard: "Yes, discard",
    addUser: "Add User",
    insertTask: "Insert Task",
  },
  general: {
    internal: "Internal",
    external: "External",
    save: "Save",
    cancel: "Cancel",
    warning: "Warning",
    name: "Name",
    search: "Search",
    ok: "OK",
    edit: "Edit",
    filter: "Filter",
    status: "Status",
    viewMode: "View mode",
    loading: "Loading...",
    lastModified: "Last Modified",
    userName: "User Name",
    blockedInParent: "Blocked in parent",
    fromParent: "From parent",
    custom: "Custom",
    nameFromGeneralSummation: "Name from general summation",
    error: "An error occurred",
    add: "Add",
    view: "View",
    delete: "Delete",
    customerSettings: "Customer Settings",
    none: "None",
    download: "Download",
    downloading: "Downloading...",
    dataRequired: "data required",
    confirmMessage: "Are you sure that you want to delete?",
    configuration: "Configuration of ",
  },
  status: {
    1: "Open",
    2: "Closed",
    3: "In Edition",
    4: "Submitted",
    5: "Accepted",
    6: "Returned",
  },
  objects: {
    masterForm: "Master Form",
    conditions: "Conditions",
    parameters: "Parameters",
    surcharges: "Surcharges",
    generalSummations: "Totals",
  },
  password: {
    title: "Change Password",
    text: "The new password must comply with the following:",
    lowercaseRequired: "At least 1 Lowercase",
    uppercaseRequired: "At least 1 Uppercase",
    numberRequired: "At least 1 Number",
    symbolRequired: "At least 1 Symbol, symbol allowed",
    charactersRequired: "Min 8 chars and Max 12 chars",
    newPassword: "New Password",
    repeatPassword: "Repeat Password",
    repeatPasswordRequired: "Repeat password is required",
    lengthError: "The password must be 8 to 12 characters length",
    patternError: "Password does not match the required pattern",
  },
  customer: {
    title: "Customers",
    fields: {
      id: "Id",
      name: "Name",
      enabled: "Enabled",
      unlimited: "Unlimited Users",
      maxUsers: "Max Users",
      currentUsers: "Current Users",
    },
    messages: {
      currentUsersMaxUsers:
        "Current users are more than the max allowed users.",
      confirmationDelete: "Are you sure that you want to delete?",
      customerDisabled: "The customer will be disabled.",
    },
    buttons: {
      showEnabledCustomers: "Show enabled customers",
      addCustomer: "Add customer",
      editCustomer: "Edit customer",
      removeCustomer: "Remove customer",
      removeCustomerPermanently: "Remove customer permanently",
    },
    placeholders: {
      newCustomerName: "Enter new customer's name",
      updateCustomerName: "Enter customer's new name",
    },
    values: {
      limited: "Limited",
      unlimited: "Unlimited",
    },
  },
  "administrative-costs": {
    buttons: {
      uploadExcel: "Upload CSV",
      downloadExcel: "Download CSV template",
    },
    fields: {
      salaryFile: "CSV File",
    },
    feedback: {
      salaryFile: "CSV file is required",
    },
    "material-tab": "Material cost catalogs",
    "personal-tab": "Personal cost catalogs",
    "material-cost-tab": {
      title: "Cost catalogs:",
      "cost-option-default": "Please select an cost cataloge",
      subtitle: "Costs",
      buttons: {
        "create-cost": "Add new cost cataloge",
        "create-cost-title": "New cost cataloge",
        "update-cost": "Edit cost cataloge",
        "delete-cost": "Delete cost cataloge",
        "upload-csv": "Import cost cataloge",
        "upload-csv-error-title": "Import cost cataloge errors",
        "download-csv": "Download cost cataloge template",
        "create-category": "Add new cost category",
        "update-category": "Update cost category",
        "delete-category": "Delete cost category",
        "update-value": "Update cost value",
        noDateSelected: "No date",
        datePickerHelp: "Use cursor keys to navigate calendar dates",
        "update-csv-title": "Update cost cataloge",
        "update-csv-error-title": "Update cost cataloge errors",
      },
      modals: {
        "create-cost-label": "Cost cataloge name:",
        "create-cost-invalid": "Name is required",
        "create-cost-placeholder": "Please enter name",
        "update-cost-label": "Cost cataloge name:",
        "update-cost-invalid": "Name is required",
        "update-cost-placeholder": "Please enter name",
        "delete-cost-message": "Are you sure you want to delete the cost type?",
        "upload-csv-label": "CSV / Excel File",
        "upload-csv-invalid": "No file chosen",
        "create-category-label": "Cost category name:",
        "create-category-invalid": "Name is required",
        "create-category-placeholder": "Please enter name",
        "create-category-label-value": "Cost category value:",
        "create-category-invalid-value": "Name is required",
        "create-category-placeholder-value": "Please enter name",
        "delete-category-message":
          "Are you sure you want to delete the cost category?",
      },
      warningMessages: {
        title: "Instruction for updating the column 'invalid':",
        first:
          "If the field in the file is filled with “Y” or “J”, the checkbox will be set",
        second:
          "If the filed in the file is filled with “N”, the checkbox will be deleted",
        third:
          "If the field is empty or contains any other content, it will be ignored",
      },
      fields: {
        unitId: "Unit ID",
        category: "Category",
        unit: "Unit",
        information: "Information",
        unitCost: "Unit Cost",
        validFrom: "Valid from",
        validTo: "Valid to",
        invalid: "Invalid",
      },
    },
  },
  export: {
    title: "Export",
    viewMode: {
      label: "Internal/External View",
      options: {
        internal: "Internal",
        external: "External",
      },
    },
    format: {
      label: "File Type",
      options: {
        pdf: "PDF",
        excel: "Excel",
      },
    },
    options: {
      comments: "Comments",
      extended: "Extended",
      yes: "Yes",
      no: "No",
    },
    buttons: {
      partialCostCalculation: "Export partial cost calculation",
      costCalculation: "Export cost calculation",
    },
  },
  partialCostCalculation: {
    perPatientOptions: [
      "No patient based calculation",
      "Calculation for single included patient",
      "Calculation for maximum number of patients",
    ],
  },
  comments: {
    button: "Comment",
    "no-comments": "No comments",
    title: "Comments case ",
    body: "Comments",
    reply: "Reply",
    resolve: "Resolve",
    "delete-warning": "You are about to delete the entry and all its answers.",
    "length-feedback":
      "The comment length must be between 1 and 2000 characters.",
    "new-comment": "New comment",
    "reply-title": "Reply to comment",
    "reply-to": "In response to",
  },
  variableCosts: {
    materialButtonTitle: "Variable Costs",
    hourlyRateButtonTitle: "Hourly rate",
    materialCommentText: "variable",
    materialExplanatoryPlaceholder:
      "For example: 120€/h as required // 3€ per km transport distance",
  },
  dataPrivacy: {
    title: "Data privacy policy",
    header: "Healex Studget Privacy Policy",
    p1: "Data protection is of a particularly high priority for the management and the staff of the Healex GmbH. Within the period of use of the Healex Studget System (https://Studget.org) of Healex GmbH, data about users of the system will not be collected at any time by tracking and profiling algorithms or other algorithms for the collection of Internet marketing technical KPIs.",
    p2: "The processing of personal data, such as the name, e-mail address, username, or user ID of the data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to the Healex GmbH. By means of this data protection declaration, we would like to inform the public about the nature, scope and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed about the rights to which they are entitled by means of this data protection declaration.",
    p3: "The Healex Studget System and thus Healex GmbH, as the controller, has implemented numerous technical and organizational measures to ensure the greatest possible protection of the personal data processed through our product (Studget). Nevertheless, Internet-based data transmissions can generally have security gaps, so an absolute protection cannot be guaranteed.",
    section1: "1. Definitions",
    s1p1: "The data protection declaration of the Healex Studget is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our privacy policy should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terms used in advance.",
    s1p2: "We use the following terms in this privacy policy, among others:",
    s1subtitle1: "Personal data",
    s1st1p1:
      'Personal data means any information relating to an identified or identifiable natural person (hereinafter referred to as "data subject"). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more special features that express the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.',
    s1subtitle2: "Data subject",
    s1st2p1:
      "Data subject is any identified or identifiable natural person whose personal data are processed by the  controller.",
    s1subtitle3: "Processing",
    s1st3p1:
      "Processing means any operation or set of operations performed on personal data, either by automated means, such as collection, recording, organisation, ordering, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination; restriction, erasure or destruction.",
    s1subtitle4: "Restriction of processing",
    s1st4p1:
      "Restriction of processing is the marking of stored personal data with the aim of restricting their future processing.",
    s1subtitle5: "Profiling",
    s1st5p1:
      "Profiling is any type of automated processing of personal data consisting in the use of such personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects relating to that natural person's work performance, economic situation, health, personal preferences, interests, reliability, behaviour, location or movement of that natural person.",
    s1subtitle6: "Pseudonymization",
    s1st6p1:
      "Pseudonymisation is the processing of personal data in such a way that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not stored by an identified or identifiable natural person.",
    s1subtitle7: "Controller or person in charge ",
    s1st7p1:
      "Controller or person in charge is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data. Where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its designation may be provided for by Union or Member State law.",
    s1subtitle8: "Processor",
    s1st8p1:
      "Processor is a natural or legal person, public authority, agency or other body that processes personal data on behalf of the controller.",
    s1subtitle9: "Receiver",
    s1st9p1:
      "The recipient is a natural or legal person, public authority, agency or other body to which personal data is disclosed, whether or not it is a third party. However, public authorities that may receive personal data in the context of a specific investigation mandate under Union or Member State law shall not be considered as recipients.",
    s1subtitle10: "Third",
    s1st10p1:
      "A third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct responsibility of the controller or processor, are authorised to process the personal data.",
    s1subtitle11: "Consent",
    s1st11p1:
      "Consent is any freely given, specific, informed and unambiguous indication of the data subject's wishes in the form of a statement or by a clear affirmative action by which the data subject indicates that he or she agrees to the processing of personal data concerning him or her.",
    section2: "2. Name and address of the person responsible for processing",
    s2p1: "The controller within the meaning of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member States of the European Union and other provisions relating to data protection is:",
    direction1: "Healex GmbH",
    direction2: "Sophienstraße 3",
    direction3: "51149 Cologne",
    phone: "Phone: +49 2203 5026368",
    email: "E-mail: info{'@'}healex.systems",
    website: "Website: https://healex.systems",
    section3: "3. Cookies",
    s3p1: "Cookies in general are data packets (text files) that are stored on your computer system via the Internet browser. ",
    s3p2: "Healex Studget uses only technically necessary cookies to authenticate the user after successful login, which is deleted when closing the browser or logging out and thus leaves no data remnants.",
    s3p3: "The system does not use cookies in any other case. ",
    s3p4: 'In the context of the GDPR, the cookie we use is not affected by the "opt-in regulation", so you do not have to actively allow cookies, cf. EU Regulation on Privacy and Electronic Communications, Art. 21.',
    section4: "4. Collection of personal data and information",
    s4p1d1: "Healex Studget ",
    s4p1d2: "does not contain",
    s4p1d3: "any data on ",
    s4p1d4: "patients",
    s4p1d5:
      " but only organizational data collected in connection with the calculation of clinical trials.",
    s4p2d1: "Specifically, therefore, only the following ",
    s4p2d2: "characteristics",
    s4p2d3: " of a user are recorded:",
    s4p3item1: "First & last name",
    s4p3item2: "E-mail address",
    s4p3item3: "Username",
    s4p3item4: "User ID",
    s4p3item5: "Healex ClinicalSite Account",
    s4p4: "When using these general data and information, the Healex GmbH does not draw any conclusions about the data subject.",
    s4subtitle1: "Rights of the data subject",
    s4st1p1:
      "Healex Studget takes the responsibility for the safekeeping of personal data of its customers very seriously. In order to provide the service agreed with Healex, both actors process personal data. The rights of data subjects described in the following subchapters ensure the legally compliant handling of personal data stored in the system.",
    s4subtitle2:
      "Lawfulness, processing fairly and in a transparent manner (Chapter 2 Art. 5 (1) a))",
    s4st2p1:
      "At Healex, the principle always applies that damage to customers must be excluded as far as possible, i.e. the dedicated user receives the best possible quality to be provided by us at Healex Studget. Therefore, only data that is absolutely necessary for technical and/or regulatory reasons will be stored.",
    s4st2p2d1: "At no time does",
    s4st2p2d2:
      ' Healex Studget process data for "performance measurement", "user experience", "tracking", "profiling" or evaluation of other Internet marketing technical KPIs.',
    s4subtitle3: "Data minimization (Chapter 2 Art. 5 (1) c))",
    s4st3p1:
      "Healex Studget only processes the personal data necessary for the management of cost calculations of clinical trials. Personal data outside this context will not be collected, stored or exported (processed) at any time.",
    s4subtitle4:
      "Information to be provided where personal data are collected from the data subject (Art. 13 GDPR)",
    s4st4p1:
      "Each data subject shall have the right granted by the European legislator to request from the controller confirmation as to whether personal data concerning him or her are being processed. If a data subject wishes to exercise this right of confirmation, he or she may, at any time, contact any employee of the controller via the above-called e-mail address.",
    s4subtitle5: "Right of access by the data subject (Art. 15 GDPR)",
    s4st5p1:
      "Each data subject shall have the right granted by the European legislator to obtain free information from the controller at any time about the personal data stored about him or her and a copy of this information. Furthermore, the European legislator has granted the data subject access to the following information:",
    s4st5p2item1: "the purposes of the processing;",
    s4st5p2item2: "the categories of personal data concerned;",
    s4st5p2item3:
      "the recipients or categories of recipient to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organisations;",
    s4st5p2item4:
      "where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;",
    s4st5p2item5:
      "the existence of the right to request from the controller rectification or erasure of personal data or restriction of processing of personal data concerning the data subject or to object to such processing;",
    s4st5p2item6:
      "the right to lodge a complaint with a supervisory authority;",
    s4st5p2item7:
      "where the personal data are not collected from the data subject, any available information as to their source;",
    s4st5p2item8:
      "the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) and, at least in those cases, meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for the data subject.",
    s4st5p3:
      "The person concerned also has a right to information as to whether personal data has been transferred to a third country or to an international organisation. If this is the case, the data subject also has the right to obtain information about the appropriate circumstances in connection with the transfer.",
    s4st5p4:
      "If a data subject wishes to exercise this right of access, he or she may, at any time, contact any employee of the controller.",
    s4subtitle6: "Right to rectification (Art. 16 GDPR)",
    s4st6p1:
      "Each data subject shall have the right granted by the European legislator to request the immediate rectification of inaccurate personal data concerning him or her. The data subject has the right, taking into account the purposes of the processing, to request the completion of incomplete personal data, including by means of a supplementary statement.",
    s4st6p2:
      "If a data subject wishes to exercise the right to rectification, he or she may, at any time, contact any employee of the controller.",
    s4st6p3:
      "After successful registration, the data subject can also carry out the correction himself within the designated area.",
    s4subtitle7: 'Right to erasure "right to be forgotten" (Art. 17 GDPR)',
    s4st7p1:
      "Each data subject shall have the right granted by the European legislator to request from the controller the eraser of personal data concerning him or her without undue delay, provided that one of the following reasons applies and insofar as the processing is not necessary:",
    s4st7p2item1:
      "the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;",
    s4st7p2item2:
      "the data subject withdraws consent on which the processing is based according to point (a) of Article 6(1) GDPR, or point (a) of Article 9(2) GDPR, and where there is no other legal ground for the processing;",
    s4st7p2item3:
      "the data subject objects to the processing pursuant to Article 21(1) GDPR and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) GDPR;",
    s4st7p2item4: "the personal data have been unlawfully processed;",
    s4st7p2item5:
      "the personal data have to be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject;",
    s4st7p2item6:
      "the personal data have been collected in relation to the offer of information society services referred to in Article 8(1) GDPR.",
    s4st7p3:
      "If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the Healex GmbH, he or she may, at any time, contact any employee of the controller. An employee of Healex GmbH shall promptly ensure that the erasure request is complied with immediately.",
    s4st7p4:
      "If the personal data have been made public by Healex GmbH and our company as the controller is obliged pursuant to Art. 17 Paragraph 1 GDPR to delete the personal data, Healex GmbH, taking into account the available technology and the implementation costs, shall take appropriate measures, including technical measures, to ensure that others are responsible for data processing, inform those processing the published personal data that the data subject has requested the erasure by such other controllers of any links to,  or copy or replication of, those personal data, to the extent that processing is not necessary. An employees of the Healex GmbH will arrange the necessary measures in individual cases.",
    s4subtitle8: "Right to restriction of processing (Art. 18 GDPR)",
    s4st8p1:
      "Each data subject shall have the right granted by the European legislator to request from the controller restriction of processing where one of the following conditions ensues:",
    s4st8p2item1:
      "the accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data;",
    s4st8p2item2:
      "the processing is unlawful and the data subject opposes the erasure of the personal data and requests the restriction of their use instead;",
    s4st8p2item3:
      "the controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims;",
    s4st8p2item4:
      "the data subject has objected to processing pursuant to Article 21(1) GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.",
    s4st8p3:
      "If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of the processing of personal data stored by the Healex GmbH, he or she may at any time contact any employee of the controller. The employee of the Healex GmbH will arrange the restriction of the processing.",
    s4subtitle9: "Right to data portability (Art. 20 GDPR)",
    s4st9p1:
      "Each data subject shall have the right granted by the European legislator to receive the personal data concerning him or her, which was provided by the data subject to a controller, in a structured, commonly used and machine-readable format. It also has the right to transmit these data to another controller without hindrance from the controller to whom the personal data have been provided, provided that the processing is based on consent pursuant to Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR or on a contract pursuant to Article 6(1)(b) of the GDPR and the processing is carried out by means of automatised procedures, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority conferred on the controller.",
    s4st9p2:
      "Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to have the personal data transmitted directly from one controller to another, insofar as this is technically feasible and provided that this does not adversely affect the rights and freedoms of other persons.",
    s4st9p3:
      "In order to assert the right to data portability, the data subject may at any time contact any employee of the Healex GmbH.",
    s4subtitle10: "Right to object (Art. 21 GDPR)",
    s4st10p1:
      "Each data subject shall have the right granted by the European legislator to object, on grounds relating to his or her particular situation, at any time, concerning processing of personal data concerning him or her, which is based on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.",
    s4st10p2:
      "The Healex GmbH shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the  establishment, exercise or defence of legal claims.",
    s4st10p3:
      "In order to exercise the right to object, the data subject may contact any employee of the Healex GmbH. The data subject is also free to exercise his or her right to object in connection with the use of information society services, notwithstanding Directive 2002/58/EC, by means of automated procedures using technical specifications.",
    section5: "5. Legal basis of processing",
    s5p1: "Art. 6 I lit. a GDPR serves our company as the legal basis for processing operations in which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, in the case of processing operations necessary for one of the services or consideration, the processing shall be based on Article 6 I lit.b GDPR. The same applies to such processing operations that are necessary for the implementation of pre-contractual measures. If our company is subject to a legal obligation which requires the processing of personal data, such as for the fulfilment of tax obligations, the processing is based on Art. 6 I lit.c GDPR.",
    section6:
      "6. Legal or contractual provisions for the provision of personal data; obligation of the data subject to provide the personal data;",
    s6p1: "We inform you that the provision of personal data is partly required by law (e.g. tax regulations) or may also result from contractual regulations (e.g. information on the contractual partner).",
    s6p2: "Sometimes it may be necessary for a contract to be concluded that a data subject provides us with personal data that must subsequently be processed by us. For example, the data subject is obliged to provide us with personal data if our company concludes a contract with him/her. Failure to provide the personal data would mean that the contract with the data subject could not be concluded.",
    s6p3: "Before personal data is provided by the data subject, the data subject must contact one of our employees.   Our employee clarifies to the data subject on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and what consequences the non-provision of the personal data would have.",
  },
};
